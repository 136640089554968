import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Site em desenvolvimento.</p>
        <p>
          Entre em contato pelo <a
          className="App-link"
          href="https://wa.me/46988093886"
          target="_blank"
        >
          WhatsApp
        </a>.
        </p>
      </header>
    </div>
  );
}

export default App;
